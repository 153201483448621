import React, { useState } from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import { colors } from "../utils/colors";

export default function List({ title, items }) {
  const [state, setState] = useState(4);
  return (
    <Paper
      elevation={state}
      sx={{
        marginBottom: 4,
      }}
      onMouseOver={() => {
        setState(15);
      }}
      onMouseOut={() => {
        setState(4);
      }}
    >
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            padding: 2.4,
            backgroundColor: colors.buttonColor,
            color: colors.white,
            fontWeight: "bold",
          }}
        >
          <Typography>{title} </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              justifyContent: "space-around",
              padding: 2.4,
              flexWrap: "wrap",
            }}
          >
            {items.map((item, index) => (
              <Typography
                key={index}
                sx={{
                  color: colors.fontColor,
                }}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
