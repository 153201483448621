import React, { useState } from "react";

import { CardContent, Card, CardHeader, Typography } from "@mui/material";
import { colors } from "../utils/colors";

export default function PricingCard({
  headerTitle,
  headerTitleColor = `${colors.fontColor}`,
  cardHeaderBgColor = `${colors.white}`,
  subTitleColor = `${colors.fontColor}`,
  subTitle,
  cardContentBgColor = `${colors.gray3}`,
  cardContent,
  cardContentTitleColor = `${colors.fontColor}`,
  cardHeaderHeight = 100,
}) {
  const [state, setState] = useState({
    headerTitleColor: headerTitleColor,
    cardHeaderBgColor: cardHeaderBgColor,
    subTitleColor: subTitleColor,
    cardContentBgColor: cardContentBgColor,

    cardContentTitleColor: cardContentTitleColor,
  });

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{
          fontSize: 46,
          fontWeight: "bold",
          color: headerTitleColor,
        }}
        subheaderTypographyProps={{
          fontSize: 20,
          color: subTitleColor,
          fontWeight: "bold",
        }}
        title={headerTitle}
        subheader={subTitle}
        sx={{
          textAlign: "center",
          height: 100,
          backgroundColor: cardHeaderBgColor,
        }}
      />
      <CardContent
        sx={{
          backgroundColor: cardContentBgColor,
          textAlign: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: cardContentTitleColor,
          }}
        >
          {cardContent}
        </Typography>
      </CardContent>
    </Card>
  );
}
