import Home from "./views/Home";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import Thankyou from "./views/Thankyou";
import ReactGA from "react-ga";
import { default as ReactGA4 } from "react-ga4";
import usePageTracking from "./utils/usePageTracking";

ReactGA.initialize("UA-60602511-1");
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA4.initialize("G-NX0EC025XS");
// ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

const theme = createTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
});

function App() {
  usePageTracking();

  return (
    <ThemeProvider theme={theme}>
      {/* <div
        style={{
          overflowX: "hidden",
        }}
      > */}
      {/* <Home /> */}
      {/* </div> */}
      {/* <Router> */}
      <Routes>
        <Route index element={<Home />} />
        <Route path="thankyou" element={<Thankyou />} />
      </Routes>
      {/* </Router> */}
    </ThemeProvider>
  );
}

export default App;
