import React from "react";
import { Container, Grid, Paper, Avatar, Typography, Box } from "@mui/material";

export default function TeamCard({ name, photo, designation, bio }) {
  return (
    <Grid item xs={12} sm={6} md={3} lg={3}>
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          textAlign: "center",
          transition: "transform 0.3s",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <Avatar
          alt={name}
          src={photo}
          sx={{ width: 120, height: 120, margin: "0 auto" }}
        />
        <Typography variant="h5" gutterBottom>
          {name}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {designation}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {bio}
        </Typography>
        {/* Add more additional information here */}
      </Paper>
    </Grid>
  );
}
