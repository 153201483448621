import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styled from "styled-components";

import { colors } from "../../utils/colors";
import LayoutButton from "../LayoutButton";
import PricingCard from "../PricingCard";
import {trackCallClick} from "../../utils/misc"

const PricingContainer = styled(Box)`
  background-color: ${colors.gray2};
`;


export default function Pricing() {
 
  const handleBookaCall = (event) => {
    trackCallClick()
  }

  return (
    <PricingContainer id="pricing">
      <Container>
        <Box
          sx={{
            textAlign: "center",
            paddingTop: { lg: "80px", md: "69px", sm: "39px", xs: "39px" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                lg: "2.4rem",
                md: "2.4rem",
                sm: "1.7rem",
                xs: "1.5rem",
              },
              fontWeight: 600,
              marginBottom: "28px",
              color: colors.fontColor,
            }}
          >
            Hire React Developer As Per Your Need
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { lg: 20, xs: "1.1275rem" },
              color: colors.fontGray,
              // paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            Simple & Transparent Pricing | Fully Signed NDA | Code Security |
            Easy Exit Policy
          </Typography>
        </Box>
        <Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item lg={3} sm={6} xs={12}>
              <PricingCard
                headerTitle="$20"
                subTitle="Hourly(USD)"
                cardContent="We’ll provide a fully signed NDA for your Project’s confidentiality"
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <PricingCard
                headerTitle="$3200"
                cardHeaderBgColor={colors.buttonColor}
                headerTitleColor={colors.white}
                subTitleColor={colors.white}
                subTitle="Monthly(USD)"
                cardContentBgColor={colors.buttonColor2}
                cardContentTitleColor={colors.white}
                cardContent="4 to 6 Years of Exp. Senior React Developer - 160 hours"
              />
            </Grid>
            <Grid item lg={3} sm={12} xs={12}>
              <PricingCard
                headerTitle="$14500"
                subTitle="Monthly(USD)"
                cardContent=" Build a SCRUM Team of 5 Developers"
              />
            </Grid>
          </Grid>
          <LayoutButton
            label="BOOK A 30 MINUTE CALL"
            topPadding={5}
            bottomPadding={5}
            href="https://calendly.com/a-mateen/outsource-react-react-native-development"
            target="_blank"
            onClick={handleBookaCall}
          />
        </Box>
      </Container>
    </PricingContainer>
  );
}
