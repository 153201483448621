import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { colors } from "../utils/colors";

const StyledText = styled(Typography)`
  color: ${colors.fontColor};
`;

export default function AppAccordion() {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          //   aria-controls="panel1bh-content"
          //   id="panel1bh-header"
        >
          <StyledText sx={{}}>
            What are the most popular types of software development services I
            can expect from Webtimate?
          </StyledText>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <StyledText>
            With battle-tested expertise in product development, project
            management, DevOps, and UI/UX, we are a one-stop shop for any of
            your software development needs. Whether you need assistance with
            skilled resources or you are looking to outsource your project, you
            can rely upon us for;
          </StyledText>

          <ul>
            <li>
              <StyledText>
                Web development using emerging technologies
              </StyledText>
            </li>
            <li>
              <StyledText>
                Mobile development using cross-platform tools
              </StyledText>
            </li>
            <li>
              <StyledText>
                Staff Augmentation – Fill the tech talent gap in your existing
                team
              </StyledText>
            </li>
            <li>
              <StyledText>
                Microsoft Dynamics 365 and PowerApps Development
              </StyledText>
            </li>
            <li>
              <StyledText>Security software development</StyledText>
            </li>
            <li>
              <StyledText>Data science</StyledText>
            </li>
            <li>
              <StyledText>DevOps</StyledText>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <StyledText sx={{}}>
            What project management tools do you use?
          </StyledText>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <StyledText>
            We use industry-standard project management tools like Jira, Trello,
            Pivotal Tracker, Asana, and Slack. Where you will be able to track
            developers' productivity and monitor the daily progress of your
            project, you can create the task, assign the work, and monitor the
            development progress.
          </StyledText>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <StyledText sx={{}}>
            How do you ensure a smooth project transition from my existing
            vendor to Webtimate?
          </StyledText>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <StyledText>
            We understand the pain of a burnt bruise; hence, without any glitch,
            using Agile methodologies, we instill your trust in us through clear
            communication, daily stand-up, and retrospective meetings in the
            scrum. Our skilled developers assure you to take over your mid-way
            left project and ease out the developmental and management troubles.
            We ensure you deliver your project on time, smoothly, and
            successfully using smart project management tools.
          </StyledText>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <StyledText sx={{}}>
            Along with React development services, what other fullstack services
            will you provide?
          </StyledText>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <StyledText>
            At Webtimate, we have top-of-the-line expertise in providing
            front-end and back-end development services. Along with React, we
            will provide various backend development services like; Ruby on
            Rails, Node and PHP to build scalable web architectures, so you
            don’t have to worry about scalability and flexibility when your
            product grows.
          </StyledText>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
