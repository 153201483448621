import React from "react";
import { colors } from "../utils/colors";
import { Box } from "@mui/material";

export default function AppChip({
  title,
  width = "40%",
  widthMd = "30%",
  widthSm = "30%",
}) {
  return (
    <Box
      sx={{
        backgroundColor: colors.white,
        height: "55px",
        width: { lg: width, md: widthMd, sm: widthSm, sx: "100%" },
        boxShadow: "0 0.25rem 7.5rem rgb(19 15 73 / 6%) !important",
        marginBottom: "1rem",
        marginRight: { lg: "1rem", md: "1rem", sm: "1rem", xs: "0" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "40px",
      }}
    >
      <span
        style={{
          fontSize: {
            lg: "1.125rem",
            md: "1.125rem",
            sm: "1.125rem",
            xs: "12px",
          },
          color: colors.fontColor,
        }}
      >
        {title}
      </span>
    </Box>
  );
}
