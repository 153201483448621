import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { Paper, Divider } from "@mui/material";

import { colors } from "../../utils/colors";

const EmailContainer = styled(Box)`
  background-color: ${colors.gray4};
  padding-top: 20px;
`;

export default function Email() {
  return (
    <EmailContainer sx={{ paddingTop: { lg: 15 } }}>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            paddingBottom: 10,
          }}
        >
          <Grid item lg={11} md={12} sm={12} xs={12}>
            <Paper
              sx={{
                padding: { lg: 10, md: 10, xs: 7 },
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                href="mailto:info@webtimate.com"
                style={{
                  textDecoration: "none",
                  color: colors.fontColor,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: {
                      lg: "43.125px",
                      md: "43.125px",
                      sm: "33px",
                      xs: "22.5px",
                    },
                    fontWeight: 600,
                  }}
                >
                  info@webtimate.com
                </Typography>
              </a>
              <Divider
                textAlign="center"
                sx={{
                  width: { lg: 420, md: 430, sm: 320, xs: 215 },
                  backgroundColor: "#ADD8E6",
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: {
                    lg: "26.625px",
                    md: "22px",
                    sm: "21px",
                    xs: "20px",
                  },
                  marginBottom: {
                    lg: "1.9375rem",
                    md: "29px",
                    sm: "29px",
                    xs: "29px",
                  },
                  color: colors.fontColor,
                }}
              >
                Your Success Is Guaranteed !
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: {
                    lg: "18.75px",
                    md: "18.75px",
                    sm: "16.874px",
                    xs: "16.874px",
                  },
                  marginBottom: { lg: "15px", xs: 2 },
                  color: colors.fontGray,
                }}
              >
                We accelerate the release of digital products and guarantee your
                success
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: {
                    lg: "18.75px",
                    md: "18.75px",
                    sm: "16.874px",
                    xs: "16.874px",
                  },
                  marginBottom: 1,
                  color: colors.fontGray,
                }}
              >
                We Use Slack, Jira & GitHub for Accurate Deployment and
                Effective Communication.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </EmailContainer>
  );
}
