import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-scroll";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import ServiceCard from "../ServiceCard";
import LayoutButton from "../LayoutButton";

const AboutUsContainer = styled(Box)``;

export default function AboutUs() {
  return (
    <AboutUsContainer id="about">
      <Container>
        <Box
          sx={{
            // paddingTop: { lg: 9, md: 7, sm: 5, xs: 5 },
            marginTop: { lg: "2%" },
            marginBottom: { lg: "5%" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: {
                lg: "2.4rem",
                md: "1.7rem",
                sm: "1.5rem",
                xs: "1.3rem",
              },
              fontWeight: 600,
              color: colors.fontColor,
              marginBottom: { lg: "3%" },
            }}
          >
            About Us
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              //   fontSize: { lg: "1.2rem", md: "1.2rem" },
              color: colors.fontGray,
              marginBottom: 4,
              marginTop: 2,
              textAlign: "justify",
            }}
            gutterBottom
          >
            At Webtimate, we're dedicated to revolutionizing your frontend
            development experience. We understand the challenges faced by
            businesses in today's competitive landscape, from talent scarcity to
            the need for rapid scaling. That's why we're here to streamline your
            frontend development with immediate onboarding and zero hassle.
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              //   fontSize: { lg: "1.2rem", md: "1.2rem" },
              color: colors.fontGray,
              marginBottom: 4,
              marginTop: 2,
              textAlign: "justify",
            }}
            gutterBottom
          >
            We Supercharge Your Frontend development with Top-tier Expertise Our
            team of dedicated developers is your ticket to offloading frontend
            development with absolute confidence. Whether it's ReactJS or React
            Native, our skilled professionals excel at creating secure,
            scalable, and dynamic web and mobile apps that perfectly align with
            your vision. With Webtimate handling the frontend, you can focus on
            what you do best.
          </Typography>
        </Box>
      </Container>
    </AboutUsContainer>
  );
}
