import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styled from "styled-components";

import { colors } from "../../utils/colors";
import {trackCallClick} from "../../utils/misc"
import LayoutButton from "../LayoutButton";

const TechStackContainer = styled(Box)`
  background-color: ${colors.gray};
  padding-bottom: 10px;
`;

export default function TechStack() {
  
  const handleBookaCall = (event) => {
    trackCallClick()
  }

  return (
    <TechStackContainer
      sx={{
        paddingTop: {
          lg: 12,
        },
        paddingBottom: {
          lg: 5,
        },
      }}
    >
      <Container id="staff">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <img src={require("../../assets/mern.png")} alt="MERN" />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <Typography
                variant="h4"
                sx={{
                  marginBottom: "28px",
                  fontSize: { lg: 34, md: "31px", sm: "25px", xs: "22px" },
                  color: colors.fontColor,
                  fontWeight: 600,
                }}
              >
                Staff Augmentation Services to Scale Your React Development Team
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: {
                    lg: "22.5px",
                    md: "17.8125px",
                    sm: "16.9125px",
                    xs: "16.9125px",
                  },
                  marginBottom: {
                    lg: "28px",
                    md: "28px",
                    sm: "28px",
                    xs: "28px",
                  },
                  color: colors.buttonColor,
                }}
              >
                Along with ReactJS, We are offering End to End Full Stack
                ReactJS Development Expertise
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: 2,
                  color: colors.fontColor,
                }}
              >
                At Webtimate, we have top-of-the-line expertise in combining a
                possible number of front and back end technologies like React +
                Rails, React + Node, React + PHP to build scalable web
                architectures, so you don’t have to worry about scalability and
                flexibility when your product grows.
              </Typography>
              <LayoutButton
                label="SCHEDULE A DEVELOPER INTERVIEW"
                lg={8}
                topPadding={4}
                bottomPadding={0}
                justifyContent="flex-start"
                href="https://calendly.com/a-mateen/outsource-react-react-native-development"
                target="_blank"
                onClick={handleBookaCall}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </TechStackContainer>
  );
}
