import * as Yup from "yup";
import "yup-phone-lite";

export const ValidationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(25, "To Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().phone("US", " Invalid number").required("Required"),
  message: Yup.string(),
  queryParam: Yup.string(),
  isHero: Yup.boolean(),
});
