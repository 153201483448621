import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { colors } from "../utils/colors";

export default function SimplePaper({ src, title, title2 }) {
  const [state, setState] = useState(2);
  return (
    <Paper
      elevation={state}
      onMouseOver={() => {
        setState(15);
      }}
      onMouseOut={() => {
        setState(2);
      }}
      sx={{
        // m: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // width: 128,
        height: 150,
        padding: 2,
      }}
    >
      <div>
        <img src={src} alt="img" />
        <Typography
          variant="subtitle1"
          sx={{ marginTop: 2, color: colors.fontColor }}
        >
          {title}
        </Typography>
        {title2 && (
          <Typography
            variant="subtitle1"
            sx={{
              color: colors.fontColor,
            }}
          >
            {title2}
          </Typography>
        )}
      </div>
    </Paper>
  );
}
