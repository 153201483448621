import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-scroll";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import ServiceCard from "../ServiceCard";
import LayoutButton from "../LayoutButton";
import TeamCard from "../TeamCard";
import { teamMembers } from "../../utils/teams";

const TeamContainer = styled(Box)``;

export default function Team() {
  return (
    <TeamContainer id="team">
      <Container>
        <Box
          sx={{
            // paddingTop: { lg: 9, md: 7, sm: 5, xs: 5 },
            marginTop: { lg: "5%" },
            marginBottom: { lg: "5%" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: {
                lg: "2.4rem",
                md: "1.7rem",
                sm: "1.5rem",
                xs: "1.3rem",
              },
              fontWeight: 600,
              color: colors.fontColor,
              marginBottom: { lg: "3%" },
            }}
          >
            Meet Our Team
          </Typography>

          <Grid
            container
            spacing={4}
            sx={{ marginTop: { lg: 0, md: 0, sm: 0, xs: "3%" } }}
          >
            {teamMembers.map((member, index) => (
              <TeamCard
                key={index}
                name={member.name}
                designation={member.designation}
                photo={member.photo}
                bio={member.bio}
              />
            ))}
          </Grid>
        </Box>
      </Container>
    </TeamContainer>
  );
}
