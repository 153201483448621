import React from "react";
import {
  Stepper,
  Box,
  Step,
  StepLabel,
  Typography,
  IconButton,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { colors } from "../utils/colors";

const steps = ["Top 1% IT Talent", "Time Zone Aligned", "Experience Team"];

const AppStep = ({ image, imgAlt, title, desc }) => {
  return (
    <>
      <Box>
        <Box
          component="img"
          sx={{
            height: "360px",
            width: "350px",
            marginBottom: { lg: 0, md: 0, sm: 3, xs: 3 },
          }}
          src={image}
          alt={imgAlt}
        />
      </Box>
      <Box
        sx={{
          width: "360px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "1.6rem", md: "1.4rem", sm: "1.2rem", xs: "1rem" },
            fontWeight: "600",
            marginBottom: {
              lg: "1.6rem",
              md: "1.6rem",
              sm: "1rem",
              xs: "1rem",
            },
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: {
              lg: "1.125rem",
              md: "1.125rem",
              sm: "1rem",
              xs: "1rem",
            },
            lineHeight: "1.875rem",
            color: `${colors.fontGray} !important`,
          }}
        >
          {desc}
        </Typography>
      </Box>
    </>
  );
};

export default function AppStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    if (activeStep < 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ marginBottom: { lg: 12, md: 10, sm: 8, xs: 5 } }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
          justifyContent: "space-evenly",
          alignItems: {
            lg: "flex-start",
            md: "flex-start",
            sm: "center",
            xs: "center",
          },
        }}
      >
        {activeStep === 0 ? (
          <AppStep
            image={require("../assets/1.jpg")}
            imgAlt={1}
            title="Top 1% IT Talent"
            desc="Webtimate is an exclusive hub of top dedicated software
            developers, UI/UX designers, QA experts, and product managers
            with an incredibly rare and hidden talents you will ever come
            across. We let you access the top 1% IT talent from independent
            software developers to the fully managed teams."
          />
        ) : activeStep === 1 ? (
          <AppStep
            image={require("../assets/2.jpg")}
            imgAlt={2}
            title="Time Zone Aligned"
            desc="Timezone is never a constraint when you are working with
            Webtimate. We follow one very simple principle – our developers
           and your time zone. Get dedicated software developers from us
           and make collaboration in a faraway to work according to your
           time zone, deadline, and milestone."
          />
        ) : (
          <AppStep
            image={require("../assets/3.jpg")}
            imgAlt={3}
            title="Experience Team"
            desc="Whether you are looking for skilled developers in emerging
            technologies or looking for an extended arms to augment your
            existing team, we can lend a helping hand in both situations. We
            are a full-stack software development company with 950+ skilled
            and experienced software developers whom you can hire at your
            convenience to address the ongoing business challenges."
          />
        )}
        <Box sx={{ marginTop: { lg: 0, md: 0, sm: 3, xs: 3 } }}>
          <IconButton
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            <WestIcon />
          </IconButton>
          <IconButton onClick={handleNext}>
            <EastIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
