import React from "react";
import EastIcon from "@mui/icons-material/East";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { colors } from "../utils/colors";

export default function LayoutButton({
  label,
  lg = 3,
  md = 5,
  sm = 6,
  topPadding = 10,
  bottomPadding = 10,
  justifyContent = "center",
  ...props
}) {
  return (
    <Box
      sx={{
        paddingTop: topPadding,
        paddingBottom: bottomPadding,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent={justifyContent}
        alignItems="center"
      >
        <Grid item lg={lg} md={md} sm={sm} xs={12}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              height: 50,
              backgroundColor: colors.buttonColor,
            }}
            endIcon={<EastIcon />}
            {...props}
          >
            {label}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
