import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styled from "styled-components";

import { colors } from "../../utils/colors";
import {trackCallClick} from "../../utils/misc"
import List from "../List";
import LayoutButton from "../LayoutButton";

const TechnicalContainer = styled(Box)`
  padding-top: 70px;
`;

export default function Technical() {
  
  const handleBookaCall = (event) => {
    trackCallClick()
  }

  return (
    <TechnicalContainer id="tech-stack">
      <Container>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginBottom: 2,
              color: colors.fontColor,
              fontSize: {
                lg: "2.6rem",
                md: "31px",
                sm: "25px",
                xs: "22px",
              },
              fontWeight: 600,
            }}
          >
            Technical Expertise of our React Developer
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: 2,
              color: colors.fontColor,
            }}
          >
            We use best-in-class tools, state-of-the-art technologies and modern
            approaches to scale up your business
          </Typography>
        </Box>
        <List
          title="Libraries"
          items={[
            "React",
            "Socket.IO",
            "Velocity.JS",
            "Canvas",
            "KendoReact",
            "Redux-Saga",
            "MUI",
            "Redux",
          ]}
        />
        <List
          title="APIs"
          items={[
            "Facebook API",
            "Instagram API",
            "YouTube API",
            "Spotify API",
            "Apple Music API",
            "Google API",
            "Jira REST API",
            "GitHub API",
          ]}
        />
        <List
          title="Platforms"
          items={[
            "AWS EC2",
            "Amazon Web Services (AWS)",
            "Linux",
            "Docker",
            "Heroku",
            "Firebase",
            "Oracle",
            "Kubernetes",
          ]}
        />
        <List
          title="Framework"
          items={["WordPress", "Drupal", "Springboot", "Next.js", "Gatsby"]}
        />
        <List
          title="Database"
          items={["MongoDB", "PostgreSQL", "MySQL", "DynamoDB"]}
        />
        <List
          title="Designer Tool"
          items={["Figma", "Zeplin", "Xd Adobe",  "Invision App"]}
        />
        <List
          title="Project Management Tools	"
          items={["Slack", "Jira", "GitHub", "Trello"]}
        />
        <LayoutButton
          label="SCHEDULE A DEVELOPER INTERVIEW"
          lg={4}
          topPadding={0}
          bottomPadding={4}
          href="https://calendly.com/a-mateen/outsource-react-react-native-development"
          target="_blank"
          onClick={handleBookaCall}
        />
      </Container>
    </TechnicalContainer>
  );
}
