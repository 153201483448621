import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import { useFormik } from "formik";
import { ValidationSchema } from "../../services/validate";
import { sendQuery } from "../../services/sendQuery";
import AppBackDrop from "../AppBackDrop";
import { useNavigate } from "react-router-dom";

const Forms = styled(Box)`
  background-color: ${colors.gray4};
`;

export default function Contact() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      queryParam: window.location.search,
      isHero: false,
    },
    validationSchema: ValidationSchema,
    onSubmit: (value) => {
      sendQuery(value, formik.resetForm, setOpen, navigate);
    },
  });
  return (
    <>
      <AppBackDrop open={open} />
      <Forms
        sx={{
          paddingTop: 8,
          paddingBottom: 8,
        }}
        onSubmit={formik.handleSubmit}
        component="form"
      >
        <Container>
          <Grid container>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: {
                    lg: "2.6rem",
                    md: "2.1rem",
                    sm: "1.6rem",
                    xs: "1.5rem",
                  },
                  textAlign: {
                    lg: "left",
                    md: "left",
                    sm: "center",
                    xs: "center",
                  },
                  fontWeight: 600,
                  color: colors.fontColor,
                  marginBottom: { lg: 0, md: 0, sm: 4, xs: 4 },
                }}
              >
                How Can We Help?
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <TextField
                      label={formik.errors.name ? formik.errors.name : "Name"}
                      placeholder="Enter your name"
                      variant="outlined"
                      name="name"
                      type="text"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && formik.errors.name}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <TextField
                      label={
                        formik.errors.email ? formik.errors.email : "Email"
                      }
                      placeholder="Enter your email"
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && formik.errors.email}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <TextField
                      label={
                        formik.errors.phone ? formik.errors.phone : "Phone no"
                      }
                      placeholder="+1-202-555-0163"
                      type="tel"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={formik.touched.phone && formik.errors.phone}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      label="Your message"
                      placeholder="Optional"
                      type="text"
                      name="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      // error={formik.touched.message && formik.errors.message}
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <Button variant="outlined" type="submit" fullWidth>
                      INQUERY NOW
                    </Button>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Container>
      </Forms>
    </>
  );
}
