import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

export default function Thankyou() {
  const navigate = useNavigate();

  return (
    <Container>
      <Box
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: {
              lg: "45px",
              md: "40px",
              sm: "30px",
              xs: "30px",
            },
            fontWeight: 600,
            color: "#34465B",
          }}
        >
          Thank you
        </Typography>
        <Typography
          variant="subtitle"
          sx={{
            fontSize: {
              lg: "13px",
              md: "13px",
              sm: "12px",
              xs: "12px",
            },
            fontWeight: 400,
            marginTop: "30px",
            marginBottom: "30px",
            textAlign: "center",
            color: "#34465B",
          }}
        >
          We have received your message and would like to thank you for writing
          to us. If your inquiry is urgent, please use the telephone number
          listed below to talk to one of our staff members. Otherwise, we will
          reply by email as soon as possible.
        </Typography>
        <Button onClick={() => navigate("/")} variant="contained">
          Back to Homepage
        </Button>
      </Box>
    </Container>
  );
}
