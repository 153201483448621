import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import { ValidationSchema } from "../../services/validate";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { sendQuery } from "../../services/sendQuery";
import AppBackDrop from "../AppBackDrop";
import { useNavigate } from "react-router-dom";

const HeroContainer = styled(Box)`
  @media screen and (max-width: 600px) {
    background-image: none !important;
    height: 800px !important;
    background-color: ${colors.black} !important;
  }
`;

const HeroLeft = styled(Box)`
  @media screen and (max-width: 600px) {
    height: 350px !important;
  }
`;

export default function Hero() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      queryParam: window.location.search,
      isHero: true

    },
    validationSchema: ValidationSchema,
    onSubmit: (value) => {
      sendQuery(value, formik.resetForm, setOpen, navigate);
    },
  });

  return (
    <HeroContainer
      sx={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${require("../../assets/webtimate_office.jpg")})`,
        height: { lg: "600px", md: "500px" },
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <AppBackDrop open={open} />
      <Container>
        <Grid
          container
          sx={{
            paddingTop: { lg: "4%", xs: "2%" },
          }}
        >
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HeroLeft
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "440px",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: colors.white,
                  paddingTop: { xs: 5, sm: 0, md: 0, lg: 0 },
                  fontSize: { lg: "3.75rem", md: "2.1rem", xs: "1.8125rem" },
                  fontWeight: 700,
                  lineHeight: "normal",
                }}
              >
                <Box
                  sx={{
                    display: {
                      md: "block",
                      xs: "none",
                    },
                  }}
                >
                  <img
                    src={require("../../assets/react.png")}
                    alt="react-logo"
                  />
                </Box>
                Outsource React Developer
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: colors.white,
                  marginTop: { xs: 2, lg: "1.875rem" },
                  marginBottom: { xs: 2, lg: "1.875rem" },
                  fontSize: { lg: "1.25rem", xs: "1.125rem" },
                  lineHeight: { lg: "1.875rem", xs: "1.8125rem" },
                }}
              >
                Hire Remote React JS Developers with the Latest Technical
                Skills.
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: colors.white,
                  marginBottom: { xs: 3, sm: 0, md: 0, lg: 0 },
                  fontSize: { lg: "1.25rem", xs: "1.125rem" },
                  lineHeight: { lg: "1.875rem", xs: "1.8125rem" },
                }}
              >
                Simple & Transparent Pricing | Fully Signed NDA | Code Security
                | Easy Exit Policy
              </Typography>
            </HeroLeft>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: { lg: "400px", md: "350px" },
                // height: { lg: "450px", md: "350px" },
                width: "350px",
                backgroundColor: colors.white,
                padding: 4,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: { lg: "1.475rem" },
                  fontWeight: { lg: 600 },
                  marginBottom: { lg: "1.175rem" },
                  color: colors.fontColor,
                }}
              >
                Hire ReactJS Developer as Per Your Need in 48 Hours
              </Typography>
              <Box
                onSubmit={formik.handleSubmit}
                component="form"
                sx={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <TextField
                  sx={{
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                  label={formik.errors.name ? formik.errors.name : "Name"}
                  placeholder="Enter your name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && formik.errors.name}
                />

                <TextField
                  sx={{
                    marginBottom: "8px",
                  }}
                  label={formik.errors.email ? formik.errors.email : "Email"}
                  placeholder="Enter your email"
                  type="email"
                  variant="outlined"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && formik.errors.email}
                  fullWidth
                />
                <TextField
                  sx={{
                    marginBottom: "8px",
                  }}
                  label={formik.errors.phone ? formik.errors.phone : "Phone no"}
                  placeholder="+1-202-555-0163"
                  variant="outlined"
                  type="tel"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && formik.errors.phone}
                  fullWidth
                />
                <TextField
                  sx={{
                    marginBottom: "8px",
                  }}
                  label="Your message"
                  placeholder="Optional"
                  variant="outlined"
                  type="text"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  // error={formik.touched.message && formik.errors.message}
                  fullWidth
                />
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    height: "50px",
                  }}
                >
                  INQUERY NOW
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </HeroContainer>
  );
}
