export const colors = {
  primary: "#212125",
  buttonColor: "#47A6DB",
  buttonColor2: "#4095c5",
  fontColor: "#222222",
  black: "#000",
  white: "#fff",
  gray: "#F0F8FF",
  fontGray: "#696969",
  gray2: "#DADADA",
  gray3: "#E9E9E9",
  gray4: "#F9F9F9",
  footerColor: "#131313",
  footerFont: "#929292",
};
