export const sendQuery = async (formData, resetForm, setOpen, navigate) => {
  // const navigate = useNavigate();
  setOpen(true);
  const data = await fetch(`https://react.webtimate.com/api/send-mail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });
  const res = await data.json();
  if (res.success) {
    setOpen(false);
    resetForm();
    navigate("/thankyou");
  } else {
    alert(res.text);
    setOpen(false);
  }
  return res;
};
