import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import styled from "styled-components";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Typography from "@mui/material/Typography";

import { colors } from "../../utils/colors";

const FooterContainer = styled(Box)`
  background-color: ${colors.footerColor};
`;
const FooterHeading = styled(Typography)`
color ${colors.white};
font-size: 21px !important;
`;
const FooterSubtitle = styled(Typography)`
color ${colors.footerFont};
font-size: 15px !important;
`;
export default function Footer() {
  return (
    <FooterContainer sx={{ paddingTop: 4, paddingBottom: 4 }}>
      <Container
        sx={{
          display: "flex",
          flexDirection: {
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            width: { lg: "200px", md: "200px", sm: "100%", xs: "60%" },
          }}
        >
          <Box>
            <FooterHeading>Address</FooterHeading>
            <FooterSubtitle sx={{}}>
              A 620,Mainframe IT Park, Royal palms, Mumbai 400065, India.
            </FooterSubtitle>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              lg: "space-evenly",
              md: "space-evenly",
              sm: "flex-start",
              xs: "flex-start",
            },
            alignItems: "center",
            flexGrow: { lg: 0.1, md: 0.1, sm: 0, xs: 0 },
            marginTop: { lg: 0, md: 0, sm: 2, xs: 2 },
          }}
        >
          <PhoneIcon
            sx={{
              color: colors.white,
              height: { lg: "40px", md: "40px", sm: "20px", xs: "20px" },
              width: { lg: "40px", md: "40px", sm: "20px", xs: "20px" },
              marginRight: { lg: 0, md: 0, sm: 2, xs: 2 },
            }}
          />
          <Box>
            <FooterSubtitle sx={{}}>Contact</FooterSubtitle>
            <a href="tel:810-863-4963" style={{ textDecoration: "none" }}>
              <FooterHeading sx={{}}>+918108634963</FooterHeading>
            </a>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              lg: "space-evenly",
              md: "space-evenly",
              sm: "flex-start",
              xs: "flex-start",
            },
            alignItems: "center",
            flexGrow: { lg: 0.1, md: 0.1, sm: 0, xs: 0 },
            marginTop: { lg: 0, md: 0, sm: 2, xs: 2 },
          }}
        >
          <EmailIcon
            sx={{
              color: colors.white,
              height: { lg: "40px", md: "40px", sm: "20px", xs: "20px" },
              width: { lg: "40px", md: "40px", sm: "20px", xs: "20px" },
              marginRight: { lg: 0, md: 0, sm: 2, xs: 2 },
            }}
          />
          <Box>
            <FooterSubtitle sx={{}}>Email</FooterSubtitle>
            <a
              href="mailto:info@webtimate.com"
              style={{ textDecoration: "none" }}
            >
              <FooterHeading sx={{}}>info@webtimate.com</FooterHeading>
            </a>
          </Box>
        </Box>
      </Container>
    </FooterContainer>
  );
}
