import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { colors } from "../../utils/colors";

const PartnersContainer = styled(Box)`
  background-color: ${colors.gray4} !important;
  height: 150px;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 700px) {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    height: 350px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export default function Partners() {
  return (
    <PartnersContainer>
      <Typography
        variant="subtitle1"
        sx={{
          color: colors.fontColor,
          fontSize: {
            lg: "1.3125rem",
            md: "1.3125rem",
            sm: "1.1875rem",
            xs: "1.1875rem",
          },
          fontWeight: 500,
        }}
      >
        Partnered with
      </Typography>
      <Box>
        <img src="" alt="" />
      </Box>
      <Box>
        <img src="" alt="" />
      </Box>
      <Box>
        <img src="" alt="" />
      </Box>
      <Box>
        <img src="" alt="" />
      </Box>
    </PartnersContainer>
  );
}
