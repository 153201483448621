import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-scroll";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import ServiceCard from "../ServiceCard";
import LayoutButton from "../LayoutButton";

const ServicesContainer = styled(Box)``;

export default function Services() {
  return (
    <ServicesContainer>
      <Container>
        <Box
          sx={{
            textAlign: "center",
            paddingTop: { lg: 9, md: 7, sm: 5, xs: 5 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                lg: "2.4rem",
                md: "1.7rem",
                sm: "1.5rem",
                xs: "1.3rem",
              },
              fontWeight: 600,
              color: colors.fontColor,
            }}
          >
            Custom React Application Development Services
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: colors.fontGray, marginBottom: 4, marginTop: 2 }}
          >
            Hire React developer with versatile skills aligning with your
            business requirements. Our React development team builds secure,
            scalable, and dynamic enterprise-grade, cost-effective React web
            apps. We have the pre-vetted React developers with expertise in all
            the versions of custom React development services. Hire React
            developer from us at your convenience.
          </Typography>
        </Box>
        <Box>
          <Grid container direction="row" spacing={4}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ServiceCard
                src={require("../../assets/icons/ProductDevelopment.png")}
                title="ReactJS Product"
                title2="Development"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ServiceCard
                src={require("../../assets/icons/Support&Maintenance.png")}
                title="ReactJS Support &"
                title2="Maintenance"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ServiceCard
                src={require("../../assets/icons/MigratingWebsite.png")}
                title="Migrating Website to "
                title2="ReactJS"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ServiceCard
                src={require("../../assets/icons/Integration.png")}
                title="ReactJS Integration"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ServiceCard
                src={require("../../assets/icons/UIUXDevelopment.png")}
                title="ReactJS UI/UX"
                title2="Development"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ServiceCard
                src={require("../../assets/icons/PluginDevelopment.png")}
                title="ReactJS Plugin"
                title2="Development"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ServiceCard
                src={require("../../assets/icons/FrontendDevelopment.png")}
                title="ReactJS Front-End"
                title2="Development"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ServiceCard
                src={require("../../assets/icons/UpgradeService.png")}
                title="ReactJS Upgrade"
                title2="Service"
              />
            </Grid>
          </Grid>
        </Box>
        <Link to="pricing" smooth={true} duration={1000}>
          <LayoutButton label="PLAN AND PRICING" />
        </Link>
      </Container>
    </ServicesContainer>
  );
}
