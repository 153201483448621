import { Box, Container } from "@mui/material";
import React from "react";
import AppStepper from "../AppStepper";

export default function Steppers() {
  return (
    <Box
      sx={{
        paddingTop: { lg: 15, md: "3.8rem", sm: "3rem", xs: "3rem" },
        paddingBottom: { lg: 15, md: "3.8rem", sm: "3rem", xs: "3rem" },
      }}
    >
      <Container>
        <AppStepper />
      </Container>
    </Box>
  );
}
