export const teamMembers = [
  {
    name: "Abdul Mateen",
    designation: "Founder",
    photo:
      "https://res.cloudinary.com/dzo2ufh6a/image/upload/v1695382712/webtimate-founder_a6bgmg.jpg",
    bio: `Abdul Mateen, the visionary force behind Webtimate. With a decade reshaping
        insurance, edtech, and D2C landscapes, his leadership fuses technical brilliance
        and strategic acumen.`,
  },
  {
    name: "Muzzamil Shaikh",
    designation: "Sr. Software Developer",
    // photo: "https://via.placeholder.com/150",
    photo:
      "https://res.cloudinary.com/dzo2ufh6a/image/upload/v1695290141/img_m7jgtl.jpg",
    bio: "Experienced project manager renowned for on-time project deliveries. Proficient in leading diverse teams and achieving project objectives. A driving force behind project success.",
  },
  {
    name: "Nitin Singh",
    designation: "Sr. Software Developer",
    photo:
      "https://res.cloudinary.com/dzo2ufh6a/image/upload/v1695290308/nitin-webtimate_ziniw0.jpg",
    bio: "Passionate software developer with expertise in multiple programming languages. Known for crafting elegant code solutions and improving user experiences through technology.",
  },
  {
    name: "Rohit Bansal",
    designation: "Sr. Software Developer",
    photo:
      "https://res.cloudinary.com/dzo2ufh6a/image/upload/v1695302372/rohit-webtimate_fzbxiu.jpg", // Replace with actual photo URL
    bio: `Seasoned team player with expertise in project coordination and execution. Detail-oriented problem solver with a passion for delivering excellence. A valuable contributor to our team's success.`,
  },
  // Add more team members as needed
];
