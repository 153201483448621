import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import { default as ReactGA4 } from "react-ga4";

const usePageTracking = () => {
    const [scriptAdded, setScriptAdded] = useState(false);

    const location = useLocation();
    const isSubDomainRoot = (window.location.host === 'react.webtimate.com' && window.location.pathname + window.location.search === '/')
    ReactGA.initialize('AW-977212519')
    ReactGA4.initialize('AW-977212519')

    console.log(location, "<<<location")
    useEffect(() => {
        ReactGA.pageview(isSubDomainRoot ? '/react_landing' : window.location.pathname + window.location.search);
        ReactGA4.send({ hitType: "pageview", page: isSubDomainRoot ? '/react_landing' : window.location.pathname + window.location.search });

        if (location.pathname === '/thankyou') {
            if (!scriptAdded) {
                // Add the Google Ads conversion tracking code
                const script = document.createElement("script");
                script.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-977212519/Tb8ICMeaxZUYEOeo_NED'});";
                document.body.appendChild(script);
                setScriptAdded(true);
                console.log("bingo!")
              }
        }

        return () => {
            const script = document.querySelector("script[src*='googleads.g.doubleclick.net']");
            if (script && script.parentNode) {
              script.parentNode.removeChild(script);
            }
            setScriptAdded(false);
          };

    }, [location]);
};

export default usePageTracking;